$('#navigation-menu-button').click(function () {
    $('body').addClass('navigation-open');
});

$('#navigation-menu-button-close').click(function () {
    $('body').removeClass('navigation-open');
});

const collapsables = Array.from(document.querySelectorAll(".collapsable-header"));
console.log(collapsables, "collapsables");

collapsables.forEach(col => {
    col.addEventListener('click', () => {
        col.parentElement.classList.toggle('open');
    });
});
